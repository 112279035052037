import clsx from 'clsx';
import Image from 'next/legacy/image';

import { PMPKeyVisualViewProps } from 'lib/themes/themes';
import { getImageUrl } from 'lib/util/imageUrl';

import baseStyles from '../base.module.css';
import styles from './PMPKeyVisualView.module.css';

const PMPKeyVisualView = ({
  headerDescription,
  logoUrl,
  supplierName,
  bannerImageUrl,
  bannerTitle,
  bannerDescription,
}: PMPKeyVisualViewProps) => {
  return (
    <div className={'base-key__text'}>
      <div className={styles['header__descSp']}>
        <p>{supplierName}</p>
        <h1>{headerDescription}</h1>
      </div>
      <div className={styles['mainvisual']}>
        <div className={styles['mainvisual__inner']}>
          <header className={clsx(styles['header'], baseStyles['color-bg'])}>
            <a className={styles['header__logo']}>
              <img src={logoUrl} />
            </a>
            <div className={styles['header__desc']}>
              <p>{supplierName}</p>
              <h1>{headerDescription}</h1>
            </div>
            <svg
              className={clsx(styles['header__ic1'], baseStyles['color-fill'])}
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
            >
              <path d="M0,32V0H32A32,32,0,0,0,0,32Z" />
            </svg>
            <svg
              className={clsx(styles['header__ic2'], baseStyles['color-fill'])}
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
            >
              <path d="M0,32V0H32A32,32,0,0,0,0,32Z" />
            </svg>
          </header>
          <Image
            priority={true}
            className={styles['mainvisual__pic']}
            layout="fill"
            src={bannerImageUrl}
            loader={({ src, width }) => getImageUrl(src, width)}
            alt="banner"
          />
          <div className={clsx(styles['mainvisual__desc'], baseStyles['color-bg'])}>
            <div className={styles['title']}>{bannerTitle}</div>
            <p>{bannerDescription}</p>
            <svg
              className={clsx(styles['mainvisual__desc__ic1'], baseStyles['color-fill'])}
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
            >
              <path d="M0,32V0H32A32,32,0,0,0,0,32Z" />
            </svg>
            <svg
              className={clsx(styles['mainvisual__desc__ic2'], baseStyles['color-fill'])}
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
            >
              <path d="M0,32V0H32A32,32,0,0,0,0,32Z" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PMPKeyVisualView;
